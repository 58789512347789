<template>
  <div id="headerCart" class="btn-basket" @click="showBasket = !showBasket">
    <i class="icon icon-shopping-basket">
      <span class="total-qty">{{ totalProducts }}</span>
    </i>
    <transition name="fade">
      <form id="basket-recap" v-if="showBasket" :action="recapPage">
        <div v-if="!basket" class="empty-basket">
          <p>Votre panier est vide</p>
        </div>
        <div :data-basket-id="basket.Id" class="basket-wrapper" v-else>
          <h1>Votre panier</h1>
          <ul class="list-items">
            <li class="item" v-for="product of basket.BasketItems" :key="'item-' + product.Id">
              <p class="item-family">{{ product.FamilyLabel }}</p>
              <p class="item-desc">
                <span class="item-count">{{ product.Quantity }} X</span>
                {{ product.Label
                }}
                <span class="coast-item">
                  {{
                  Number(product.UnitPrice * product.Quantity).toFixed(2)
                  }}
                  €
                </span>
              </p>
            </li>
          </ul>

          <div class="payment-box">
            <button class="btn-payment">Allez au paiement</button>
            <div class="total-coast">
              Total:
              <span class="total-number">{{ Number(basket.Amount).toFixed(2) }} €</span>
            </div>
          </div>
        </div>
      </form>
    </transition>
  </div>
</template>

<script>
export default {
  name: "widget",
  data() {
    return {
      showBasket: false,
      IdPanier: null,
      MaJPanier: false,
      basket: null
    };
  },
  props: {
    baseWebservice: String,
    recapPage: String
  },
  beforeMount() {
    this.IdPanier = localStorage.getItem("IdPanier");
    this.MaJPanier = localStorage.getItem("MaJPanier");
    if (!this.basket) {
      this.MaJPanier = "true";
    }
    if (this.IdPanier && this.MaJPanier === "true") {
      localStorage.setItem("MaJPanier", "false");
      fetch(
        this.baseWebservice
          ? this.baseWebservice
          : window.location.origin +
              "/SAPI/GATWebserv/FetchBasket?jsonparameter={IdPanier:" +
              this.IdPanier +
              "}"
      )
        .then(rep => {
          rep.json().then(r => (this.basket = r.basket));
        })
        .catch(e => console.log(e));
    }
    setInterval(() => {
      if (this.IdPanier && this.MaJPanier === "true") {
        localStorage.setItem("MaJPanier", "false");
        fetch(
          this.baseWebservice
            ? this.baseWebservice
            : window.location.origin +
                "/SAPI/GATWebserv/FetchBasket?jsonparameter={IdPanier:" +
                this.IdPanier +
                "}"
        )
          .then(rep => {
            rep.json().then(r => (this.basket = r.basket));
          })
          .catch(e => console.log(e));
      }
    }, 1000);
  },
  computed: {
    totalProducts() {
      let total = 0;
      if (this.basket) {
        if (this.basket.BasketItems) {
          this.basket.BasketItems.forEach(p => {
            total += p.Quantity;
          });
        }
      }
      return total;
    },
    actionPage() {
      if (this.recapPage.indexOf("/") === 1) {
        return this.recapPage;
      }
      return "/" + this.recapPage;
    }
  }
};
</script>

<style lang="scss">
#headerCart {
  width: 36px;
  height: 36px;
  margin-right: 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1em;
  color: #4c4d4f;

  .icon.icon-shopping-basket {
    cursor: pointer;
    background: url(./assets/img/shopping-basket-solid.svg) no-repeat;
    width: 1.5rem;
    height: 1.5rem;
    background-size: contain;
  }

  .total-qty {
    background: #ff3300;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    font-size: 0.7em;
    font-family: "Open Sans", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 100;
    font-style: normal;
  }

  .empty-basket {
    p {
      margin: 0;
      display: block;
      text-align: center;
    }
  }

  #basket-recap {
    position: absolute;
    top: 36px;
    right: 0;
    z-index: 1;
    background: #f1f2f2;
    padding: 16px;
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 360px;
    min-width: 175px;

    &::before {
      content: " ";
      width: 16px;
      height: 16px;
      background: #f1f2f2;
      position: absolute;
      top: -8px;
      right: 12px;
      transform: rotate(45deg);
    }

    .basket-wrapper {
      min-width: 20vw;

      h1 {
        text-align: center;
        font-weight: bold;
        font-size: 1.1em;
        padding-bottom: 8px;
      }

      h1,
      .item {
        margin-bottom: 16px;
        border-bottom: 1px solid #ff3300;
      }
    }

    .list-items {
      padding: 0 8px;
      list-style: none;

      .item > p {
        margin: 8px;
      }

      .item-family {
        color: #ff3300;
        text-align: left;
      }

      .item-desc {
        display: flex;
        padding-left: 16px;
      }

      .item-count {
        margin-right: 4px;
      }
      .coast-item {
        margin-left: auto;
      }
    }

    .payment-box {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn-payment,
      .total-coast {
        height: 2.5rem;
        flex: 1;
      }

      .btn-payment {
        cursor: pointer;
        background: #ff3300;
        border: 2px solid #ff3300;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        transition: all 0.3s ease-in-out;
      }
      .btn-payment:hover {
        background: white;
        color: #ff3300;
      }

      .total-coast {
        border: 2px solid #504e50;
        box-sizing: border-box;
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
      }
      .total-number {
        color: #ff3300;
        font-weight: bold;
        margin-left: 4px;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
